.ProjectDetail{
    width:90%;
    margin-left: 10%;
}
.Projectlogo{
    width: 100%;
    height: 300px;

}

.ProjectDetailCard{
    display: flex;
    margin-top: 10px;
    width:70%;
    margin-left:20%;
    .Card{
        width: 90%;
        justify-content: center;
        position: relative;
        display: block;
        height:20%;
        .Info{
            display:block;
            font-size: 20px;
            font-weight: bolder;
            width:40%;
            margin: 0;
            padding: 0;
            font-family: 'Gilroy-Light';
            .CoinName{
                display:flex;
                
                width: 100%;
                .Name{
                    display:flex;
                    width:60%;
                    text-align: left;
                    margin-bottom: 10px;
                    h5{
                        text-decoration-line: underline;
                        
                        margin: 0;
                    }
                }
                
                
            }
            .Coin{
                display: ruby;
                width:30%;
                text-align: right;
                text-decoration-line: underline;
                h4{
                    margin:0;
                    color:  #00783f;
                }
            }
            .Raise{
                display: ruby;
                width:30%;
                text-align: right;
                text-decoration-line: underline;
                h4{
                    margin:0;
                    font-size: 14px;
                    
                }
            }
        }
        .projectDetail{
            h5{
             margin:0;
             text-decoration-line: underline;
             font-weight: lighter;
            }
         }
    }

    

    
}
.projectBrief{
    margin-left:15%;
    margin-right:15%;
    display: relative;
    p{
        font-size: 18px;
    }
    .talkingPoints
    {
        ul{
            margin:0;
            margin-left:3%;
            text-align: left;
            li::marker {
                color: #00783f;
                font-size: 1.3em;
              }
              
        }
    }
    .assests {
        display: block;
        button{
            margin-top: 3%;
            text-align: center;
            width:20%; 
            height:5%; 
            border: 1px solid;
            background-color: #00783f;
            text-decoration-line: underline;
            color: #EEF1F8;
            margin-bottom: 10px;
        }
    }
}

.ProjectImage{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border-style: dashed;
    margin-right: 15px;
    
  }