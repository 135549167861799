.container{
    display: block;
    height: 100%;
}

.dashboard{
    margin-left: 15%;
    margin-right: 5%;
    height: 100%;
    display:block;
}
.earning{
    display: flex;
    justify-content: space-between;
    padding:0px 75px;
}


.referral {
    display: block;
    button{
        margin-top:-10px ;
        margin-left:40%;
        text-align: center;
        width:20%; 
        height:10%; 
        border: 1px solid;
        background-color: #00783f;
        color: white;
        margin-bottom: 10px;
    }

}
