.trending-project{
    display: flex;
    padding: 0;
    width: 100%;
    .projectCard{
        width: 90%;
        position: relative;
        display: block;
        .projectInfo{
            display:flex;
            
            font-size: 18px;
            font-family: 'Gilroy-bold';
            h5{
                margin-bottom: 10px;
                margin-left: 5px;
            }
        }
    }
    
}

.projectImage{
    width: 10%;
    height: 50px;
    margin-top:30px;
    border-radius: 50%;
    border-style: dashed;
    margin-right: 15px;
    
  }

  .progressBarLabel{
    width: 90%;
    display:flex;
    justify-content: space-between;
    font-size: small;
    margin-top: 4px;
    font-weight: 600;
  }
