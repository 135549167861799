.AvailableProject{
    display: flex;
    padding: 0;
    width: 80%;
    .Card{
        width: 90%;
        position: relative;
        display: block;
        height:20%;
        .Info{
            display:block;
            font-size: 18px;
            width:40%;
            margin: 0;
            padding: 0;
            font-family: 'Gilroy-Light';
            .CoinName{
                display:flex;
                width: 100%;
                .Name{
                    display:flex;
                    width:60%;
                    text-align: left;
                    margin-bottom: 10px;
                    h5{
                        text-decoration-line: underline;
                    }
                }
                
                .Coin{
                    width:40%;
                    text-align: right;
                    margin-bottom: 10px;
                    margin-bottom: 10px;
                    text-decoration-line: underline;
                }
            }
            
        }
        .projectDetail{
           h5{
            margin:0;
            text-decoration-line: underline;
            font-weight: lighter;
           }
        }
    }
    
}

.Image{
    width: 10%;
    height: 3%;
    border-radius: 50%;
    border-style: dashed;
    margin-right: 15px;
    
  }

  .promote {
    display: flex;
    button{
        margin-top: 3%;
        text-align: center;
        width:40%; 
        height:5%; 
        border: 1px solid;
        background-color: #00783f;
        text-decoration-line: underline;
        color: #EEF1F8;
        margin-bottom: 10px;
    }

}
