.customTable {
    width: 80%;
    border-collapse: collapse;
    margin:0 10%;
  }
  table, th, td{
    border-bottom: ridge;
  }
  .customTable th,
  .customTable td {
    padding: 8px;
    width:33%;
  }
  
  .customTable th {
    color: rgb(53, 37, 37);
  }
  
  .customTable tr:nth-child(even) {
    border-bottom: 5cm;
  }
  
  .customTable tr:hover {
    background-color: #ddd;
  }
  
  .customTable img {
    vertical-align: middle;
  }
  
  .Circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),radial-gradient(113% 91% at 17% -2%, #FF5A00FF 1%, #FF000000 99%),radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
  }

.Ambassador{
    text-align: left;
      }

.Rank, .Raise{
text-align: center;
}
