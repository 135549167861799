@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Gilroy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Bold'), url(./fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Medium'), url(./fonts/gilroy/Gilroy-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Light'), url(./fonts/gilroy/Gilroy-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Regular'), url(./fonts/gilroy/Gilroy-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-SemiBold'), url(./fonts/gilroy/Gilroy-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-ExtraBold'), url(./fonts/gilroy/Gilroy-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}
