
.wrapper{
  display: flex;
  position: relative;
}

.wrapper .sidebar{
  width: 10%;
  height: 100%;
  background: #161324;
  padding: 10px 0px;
  position: fixed;
}

.wrapper .sidebar .Sidebarlogo{
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapper .sidebar ul li{
  padding: 15px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: large;
  border-bottom: 1px solid #bdb8d7;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top: 1px solid rgba(255,255,255,0.05);
}    
ul{
    padding-left: 0px;
    text-align: center;
    margin-top: 250px;
}
.wrapper .sidebar ul li a{
  color: #bdb8d7;
}

.wrapper .sidebar ul li a .fas{
  width: 25px;
}

    
.wrapper .sidebar ul li:hover a{
  color: #31a353;
}
 
