.Login,.Register {
  text-align: center;
  display:flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color:white;
  background-image:linear-gradient(7deg,#39db5c,#75c66f 48%,#d2f74d)
}

.auth-form-container, .login-form,.register-form{
  display: flex;
  flex-direction: column;
  padding: 5rem;
}

.auth-form-container{

  padding: 5rem;
  border: 1px solid white;
  border-radius: 10px;
  margin:0.5rem
}

label{
  text-align:left;
  padding:0.25rem 0;
}

input{
  margin:0.5rem 0;
  padding:1rem;
  border:none;
  border-radius:10px;

}

button{
  border:None;
  background-color: white;
  padding:20px;
  border-radius:10px;
  cursor: pointer;
  color:#7439db;
}

.link-btn{
  background:none;
  color: white;
  text-decoration: underline;
}